import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import { BaseURL } from "../../BaseUrl";
import { Editor } from "primereact/editor";
import { Link } from "react-router-dom";
import { htmlToText } from "html-to-text";

export default function AddService() {
  const [name, setName] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [description, setDescription] = useState("");
  const [clientIp, setClientIp] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Fetch the client's IP address
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setClientIp(response.data.ip);
      })
      .catch((error) => {
        console.error("There was an error fetching the IP address!", error);
      });

    // Fetch subcategories
    axios
      .get(`${BaseURL}/getallsubcategories`)
      .then((response) => {
        setSubcategories(response.data.results);
      })
      .catch((error) => {
        console.error("There was an error fetching the subcategories!", error);
      });
  }, []);

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "This field is required.";
    tempErrors.actualPrice = actualPrice ? "" : "This field is required.";
    // tempErrors.discountedPrice = discountedPrice ? "" : "This field is required.";
    tempErrors.description = description ? "" : "This field is required.";
    tempErrors.selectedSubcategory = selectedSubcategory
      ? ""
      : "This field is required.";
    if (actualPrice && Number(discountedPrice) > Number(actualPrice)) {
      tempErrors.discountedPrice =
        "Discounted price cannot be greater than actual price.";
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    if (validate()) {
      const newService = {
        category_subtype_id: selectedSubcategory,
        name: name,
        actual_price: actualPrice,
        discounted_price: discountedPrice,
        description: description,
        ip: clientIp,
      };
      setIsSubmitting(true);

      axios
        .post(`${BaseURL}/addservice`, newService)
        .then((response) => {
          if (response.data.success) {
            alert("Service added successfully!");
            // Reset form fields
            setName("");
            setActualPrice("");
            setDiscountedPrice("");
            setDescription("");
            setSelectedSubcategory("");
            setErrors({});
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.error("There was an error adding the service!", error);
          alert("There was an error adding the service. Please try again.");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 className="card-title">Add New Service</h4>
                    <Link
                      to="/services"
                      style={{
                        backgroundColor: "#f44336",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Back
                    </Link>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Service Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      />
                      {errors.name && (
                        <div className="error" style={{ color: "red" }}>
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="subcategory">Subcategory</label>
                      <select
                        className="form-control"
                        id="subcategory"
                        value={selectedSubcategory}
                        onChange={(e) => setSelectedSubcategory(e.target.value)}
                        required
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      >
                        <option value="">Select Subcategory</option>
                        {subcategories.map((subcategory) => (
                          <option key={subcategory.id} value={subcategory.id}>
                            {subcategory.name}
                          </option>
                        ))}
                      </select>
                      {errors.selectedSubcategory && (
                        <div className="error" style={{ color: "red" }}>
                          {errors.selectedSubcategory}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="actualPrice">Actual Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="actualPrice"
                        value={actualPrice}
                        onChange={(e) => setActualPrice(e.target.value)}
                        required
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      />
                      {errors.actualPrice && (
                        <div className="error" style={{ color: "red" }}>
                          {errors.actualPrice}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="discountedPrice">Discounted Price</label>
                      <input
                        type="number"
                        className="form-control"
                        id="discountedPrice"
                        value={discountedPrice}
                        onChange={(e) => setDiscountedPrice(e.target.value)}
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      />
                      {errors.discountedPrice && (
                        <div className="error" style={{ color: "red" }}>
                          {errors.discountedPrice}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <Editor
                        style={{
                          height: "320px",
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                        }}
                        value={description}
                        onTextChange={(e) => setDescription(e.htmlValue)}
                        required
                      />
                      {errors.description && (
                        <div className="error" style={{ color: "red" }}>
                          {errors.description}
                        </div>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary mt-3"
                      style={{
                        backgroundColor: "#4CAF50",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Add Service "}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
