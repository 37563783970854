import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import { BaseURL } from "../../BaseUrl";
import { Link } from "react-router-dom";
import { Editor } from "primereact/editor";
import { Country, State, City } from "country-state-city";

export default function Setting() {
  const [settings, setSettings] = useState({
    facebook_url: "",
    twitter_url: "",
    instagram_url: "",
    razor_key: "",
    youtube_url: "",
    logo: null,
    sign: null,
    name: "",
    gstin: "",
    cin: "",
    pan: "",
    address: "",
    location_link: "",
    official_phone: "",
    official_email: "",
    payment_email: "",
    privacy_policy: "",
    terms_and_conditions: "",
    country: "", // New state for country
    state: "", // New state for state
    city: "", // New state for city
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [selectedStateIso2, setSelectedStateIso2] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/getallsettingdetails`);
        const data = response.data.results;

        setSettings((prevSettings) => ({
          ...prevSettings,
          facebook_url: data.facebook_url,
          twitter_url: data.twitter_url,
          instagram_url: data.instagram_url,
          razor_key: data.razor_key,
          youtube_url: data.youtube_url,
          logo: data.logo,
          sign: data.sign,
          name: data.name,
          gstin: data.gstin,
          pan: data.pan,
          cin: data.cin,
          address: data.address,
          official_phone: data.official_phone,
          location_link: data.location_link,
          official_email: data.official_email,
          payment_email: data.payment_email,
          privacy_policy: data.privacy_policy,
          terms_and_conditions: data.terms_and_conditions,
          country: data.country,
          state: data.state,
          city: data.city,
        }));
      } catch (error) {
        console.error("Error fetching settings data", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleEditorChange = (name, value) => {
    setSettings({ ...settings, [name]: value });
  };

  const handleFileChange = (e) => {
    setSettings({ ...settings, logo: e.target.files[0] });
  };
  const handleFileChange1 = (e) => {
    setSettings({ ...settings, sign: e.target.files[0] });
  };

  useMemo(() => {
    if (countries.length) {
      const result = countries.find(
        (country) => country.name === settings.country
      );
      console.log(result);
      setSelectedCountryIso2(result.iso2 || "");
    }
  }, [countries, settings.country]);

  useMemo(() => {
    if (states.length) {
      console.log("states", states);
      console.log(settings.city);
      const result = states.find((state) => state.name === settings.state);
      console.log("rsult", result);
      setSelectedStateIso2(result?.iso2 || "");
    }
  }, [states, settings.state]);

  useEffect(() => {
    const loadCountries = () => {
      axios
        .get("https://api.countrystatecity.in/v1/countries", {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        })
        .then((response) => {
          setCountries(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error loading countries:", error);
        });
    };

    loadCountries();
  }, []);

  const onChangeCountryHandler = (e) => {
    const selectedCountry = e.target.value;
    const country = countries.find(
      (country) => country.name === selectedCountry
    );
    if (country) {
      setSettings((prev) => ({
        ...prev,
        country: country.name,
        state: "", // Reset state when country changes
        city: "", // Reset city when country changes
      }));
      setSelectedCountryIso2(country.iso2);
      // loadStates(country.iso2);
    }
  };

  useEffect(() => {
    if (selectedCountryIso2) loadStates(selectedCountryIso2);
  }, [selectedCountryIso2]);

  // Fetch states based on selected country
  const loadStates = (iso2) => {
    axios
      .get(`https://api.countrystatecity.in/v1/countries/${iso2}/states`, {
        headers: {
          "X-CSCAPI-KEY":
            "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
        },
      })
      .then((response) => {
        console.log("response state", response.data);
        setStates(response.data || []);
      })
      .catch((error) => {
        console.error("Error loading states:", error);
      });
  };

  // Handle state selection
  const onChangeStateHandler = (e) => {
    const selectedState = e.target.value;
    const state = states.find((state) => state.name === selectedState);
    if (state) {
      setSettings((prev) => ({
        ...prev,
        state: state.name,
        city: "",
      }));
      setSelectedStateIso2(state.iso2);
    }
  };

  // Fetch cities based on selected state and country
  const loadCities = (countryIso2, stateIso2) => {
    axios
      .get(
        `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        }
      )
      .then((response) => {
        setCities(response.data);
      })
      .catch((error) => {
        console.error("Error loading cities:", error);
      });
  };

  useEffect(() => {
    if (selectedCountryIso2 && selectedStateIso2)
      loadCities(selectedCountryIso2, selectedStateIso2);
  }, [selectedCountryIso2, selectedStateIso2]);

  const handleSave = async (field) => {
    try {
      let response;
      if (field === "logo") {
        const formData = new FormData();
        formData.append("logo", settings.logo);
        response = await axios.put(`${BaseURL}/updatelogo`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else if (field === "sign") {
        const formData = new FormData();
        formData.append("sign", settings.sign);
        response = await axios.put(`${BaseURL}/updatesign`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }else if (
        [
          "facebook_url",
          "twitter_url",
          "instagram_url",
          "razor_key",
          "youtube_url",
        ].includes(field)
      ) {
        response = await axios.put(`${BaseURL}/updateicon`, {
          name: field,
          url: settings[field],
        });
      } else if (field === "country_state_city") {
        response = await axios.put(`${BaseURL}/updatecompanydetails`, {
          country: settings.country,
          state: settings.state,
          city: settings.city,
        });
      } else {
        const updateData = { [field]: settings[field] };
        response = await axios.put(
          `${BaseURL}/updatecompanydetails`,
          updateData
        );
      }

      if (response.data.success) {
        alert("Successfully updated!");
        console.log(`Saved ${field}:`, settings[field]);
      } else {
        console.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error(`Error saving ${field}`, error);
      alert(`Error saving ${field}`, error);
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>Settings</h4>
                    <div>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#007bff",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          textDecoration: "none",
                          marginLeft: "20px",
                        }}
                      >
                        <Link
                          to="/advertisement"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Add Advertisement
                        </Link>
                      </button>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#007bff",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          textDecoration: "none",
                          marginLeft: "20px",
                        }}
                      >
                        <Link
                          to="/gallaryimages"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Add Gallary Images
                        </Link>
                      </button>
                    </div>
                  </div>
                  <form style={{ height: "80vh", overflowY: "scroll" }}>
                    <h2>Company Details</h2>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="address">Company Name</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={settings.name}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("name")}
                              >
                                Save
                              </button>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="official_phone">GSTIN</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="gstin"
                                name="gstin"
                                className="form-control"
                                value={settings.gstin}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("gstin")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="official_phone">CIN</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="cin"
                                name="cin"
                                className="form-control"
                                value={settings.cin}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("cin")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="official_phone">PAN</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="pan"
                                name="pan"
                                className="form-control"
                                value={settings.pan}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("pan")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="address">
                              Company Full Address
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="address"
                                name="address"
                                className="form-control"
                                value={settings.address}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("address")}
                              >
                                Save
                              </button>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="official_phone">
                              Company Map Location
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="location_link"
                                name="location_link"
                                className="form-control"
                                value={settings.location_link}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("location_link")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-row"
                      style={{ display: "flex", alignItems: "center", justifyContent:'space-between' }}
                    >
                      <div
                        className="form-group"
                        style={{ flex: 1, marginRight: "10px" }}
                      >
                        <label htmlFor="country">Country</label>
                        <select
                          id="country"
                          name="country"
                          className="form-control"
                          value={settings.country}
                          onChange={onChangeCountryHandler}
                          required
                        >
                          <option value="">Select Country</option>
                          {countries.map((country) => (
                            <option key={country.iso2} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="form-group"
                        style={{ flex: 1, marginRight: "10px" }}
                      >
                        <label htmlFor="state">State</label>
                        <select
                          id="state"
                          name="state"
                          className="form-control"
                          value={settings.state}
                          onChange={onChangeStateHandler}
                          required
                          disabled={!settings.country}
                        >
                          <option value="">Select State</option>
                          {states.map((state) => (
                            <option key={state.name} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="form-group"
                        style={{ flex: 1, marginRight: "10px" }}
                      >
                        <label htmlFor="city">City</label>
                        <select
                          id="city"
                          name="city"
                          className="form-control"
                          value={settings.city}
                          onChange={(e) =>
                            setSettings((prev) => ({
                              ...prev,
                              city: e.target.value,
                            }))
                          }
                          required
                          disabled={!settings.state}
                        >
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option key={city.name} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <button
                        type="btn btn-primary"
                        className="btn btn-primary"
                        style={{ height: "40px" }}
                        onClick={() => handleSave("country_state_city")}
                      >
                        Save
                      </button>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="official_email">
                              Company Email
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="official_email"
                                name="official_email"
                                className="form-control"
                                value={settings.official_email}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("official_email")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="official_phone">
                              Company Phone
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="official_phone"
                                name="official_phone"
                                className="form-control"
                                value={settings.official_phone}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("official_phone")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="payment_email">
                              Payment Notification Email
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="payment_email"
                                name="payment_email"
                                className="form-control"
                                value={settings.payment_email}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("payment_email")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="logo">Company Logo</label>
                            <div className="input-group">
                              <input
                                type="file"
                                id="logo"
                                name="logo"
                                className="form-control"
                                onChange={handleFileChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("logo")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <img
                              src={settings.logo}
                              alt="Company Logo"
                              style={{ maxWidth: "100px", height: "100px" }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="sign">Invoice Signature</label>
                            <div className="input-group">
                              <input
                                type="file"
                                id="sign"
                                name="sign"
                                className="form-control"
                                onChange={handleFileChange1}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("sign")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <img
                              src={settings.sign}
                              alt="signature"
                              style={{ maxWidth: "100px", height: "100px" }}
                            />
                          </div>
                        </div>



                        <h2>Social Media Links</h2>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="facebook_url">Facebook Url</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="facebook_url"
                                name="facebook_url"
                                className="form-control"
                                value={settings.facebook_url}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("facebook_url")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="twitter_url">Twitter Url</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="twitter_url"
                                name="twitter_url"
                                className="form-control"
                                value={settings.twitter_url}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("twitter_url")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="instagram_url">Instagram Url</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="instagram_url"
                                name="instagram_url"
                                className="form-control"
                                value={settings.instagram_url}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleSave("instagram_url")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="razor_key">Razor Pay Key</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="razor_key"
                                name="razor_key"
                                className="form-control"
                                value={settings.razor_key}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("razor_key")}
                              >
                                Save
                              </button>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="youtube_url">YouTube Url</label>
                            <div className="input-group">
                              <input
                                type="text"
                                id="youtube_url"
                                name="youtube_url"
                                className="form-control"
                                value={settings.youtube_url}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => handleSave("youtube_url")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    

                        <h2>Privacy Policy</h2>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <Editor
                              style={{ height: "320px" }}
                              value={settings.privacy_policy}
                              onTextChange={(e) =>
                                handleEditorChange(
                                  "privacy_policy",
                                  e.htmlValue
                                )
                              }
                            />
                            <button
                              type="button"
                              className="btn btn-primary mt-2"
                              onClick={() => handleSave("privacy_policy")}
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        <h2>Terms and Conditions</h2>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <Editor
                              style={{ height: "320px" }}
                              value={settings.terms_and_conditions}
                              onTextChange={(e) =>
                                handleEditorChange(
                                  "terms_and_conditions",
                                  e.htmlValue
                                )
                              }
                            />
                            <button
                              type="button"
                              className="btn btn-primary mt-2"
                              onClick={() => handleSave("terms_and_conditions")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
